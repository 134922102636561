var layout = {

    $body: null,

    initDomElements: function () {
        layout.$body = $('body');
    },

    init: function () {
        layout.initDomElements();
        layout.$body.addClass('ani');
        layout.initScrollerBtn();
        layout.initScrollListener();
        layout.initSlider();
    },

    initScrollerBtn: function () {
        var $htmlBody = $('html, body');
        var $scrollBtn = $('.js-scroll-btn');

        $scrollBtn.click(function (event) {
            event.preventDefault();

            var full_url = this.href;
            var parts = full_url.split("#");
            var trgt = parts[1];

            var target_offset = $("#" + trgt).offset();
            var target_top = target_offset.top;

            $htmlBody.animate({scrollTop: target_top}, 1000);
        });
    },

    initScrollListener: function () {
        var last_known_scroll_position = 0;
        var ticking = false;
        var windowOnTop = true;

        scrollHandler();

        window.addEventListener('scroll', function () {
            scrollHandler();
        });

        function scrollHandler() {
            last_known_scroll_position = window.scrollY;
            if (!ticking) {
                window.requestAnimationFrame(function () {
                    onScrollUpdate(last_known_scroll_position);
                    ticking = false;
                });
            }
            ticking = true;
        }

        function onScrollUpdate(scroll_pos) {
            if (scroll_pos == 0 && !windowOnTop) {
                layout.$body.removeClass('scrolled');
                windowOnTop = true;
            } else if (windowOnTop && scroll_pos > 0) {
                layout.$body.addClass('scrolled');
                windowOnTop = false;
            }
        }
    },

    initSlider: function () {
        var $jsSlider = $('.js-slider');

        var $jsSliderVarWidth = $('.js-slider-var-width');
        var $jsSliderSameWidth = $('.js-slider-same-width');

        var updateSlideIndexClass = function (slick, currentActiveSlide) {
            var position = -currentActiveSlide;
            var $slides = slick.$slides;

            $slides.each(function () {
                var $slide = $(this);
                $slide.attr('pos', position);
                position++
            });
        };

        $jsSlider.on('init', function (event, slick) {
            setTimeout(function () {
                $('.js-slider').addClass('style-initialized');
                var currentSlideIndex = 0;
                for (var i = 0, iLen = slick.$slides.length; i < iLen; i++) {
                    if ($(slick.$slides[i]).hasClass('slick-current')) {
                        currentSlideIndex = i;
                    }
                }

                updateSlideIndexClass(slick, currentSlideIndex);
            }, 20);
        });

        $jsSliderVarWidth.slick({
            centerMode: false,
            arrows: false,
            infinite: false,
            variableWidth: true,
            dots: true,
            slidesToShow: 1
        }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            setTimeout(function () {
                updateSlideIndexClass(slick, nextSlide);
            }, 20);
        }).on('afterChange', function (event, slick, currentSlide) {
            setTimeout(function () {
                updateSlideIndexClass(slick, currentSlide);
            }, 20);
        });


        $jsSliderSameWidth.slick({
            centerMode: false,
            arrows: false,
            infinite: false,
            variableWidth: false,
            dots: true,
            slidesToShow: 1
        }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            setTimeout(function () {
                updateSlideIndexClass(slick, nextSlide);
            }, 20);
        }).on('afterChange', function (event, slick, currentSlide) {
            setTimeout(function () {
                updateSlideIndexClass(slick, currentSlide);
            }, 20);
        });
    }
};